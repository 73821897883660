<template>
  <SiteMain>
    <GridSection>
      <header class="heading col-p">
        <h2>‘{{ $route.query.for }}’ {{ $t('in search results') }}</h2>
        <p>{{ $t('These are the results with some of the keywords.') }}</p>
      </header>
      <template v-if="results.length > 0">
        <Card
          class="col-md-6 col-lg-4 col-p"
          v-for="(result, i) in results"
          :key="i"
          :title="result.title"
          :image="result.image"
          :link="result.slug"
          :duration="result.view_time"
          :phase="result.phase_title"
          :date="result.event_date" />
      </template>
      <template v-else>
        <div class="no-results">
          <img src="~@/assets/img/logos/mercedes-icon.svg" alt="" />
          <p v-html="$t('no_search_result')"></p>
        </div>
      </template>
    </GridSection>
  </SiteMain>
</template>

<script>
import axios from "@/helpers/axios";
import SortBy from "@/components/SortBy.vue";
import SiteMain from "@/components/SiteMain.vue";
import GridSection from "@/components/GridSection.vue";
import Card from "@/components/Card.vue";

export default {
  components: {
    SortBy,
    SiteMain,
    GridSection,
    Card,
  },
  data() {
    return {
      headingTitle: "Content",
      results: [],
      errors: [],
    };
  },
  methods: {
    async doSearch() {
      try {
        //stop wat er in de URL staat (in for=...) in een variabele searchInput
        const searchInput = this.$route.query.for;

        //gebruik searchinput om resultaten op te halen uit endpoint 1
        //let op, we moeten nog url uit env file halen zodat deze naar prod endpoint gaat op productie en acc endpoint op acc/development
        //let op, de locale is nu nog hardcoded 'en' en deze moet naar bijv nl gezet worden indien de taal van website op nl staat
        // ${process.env.BASE_URL}
        const searchResults = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/content/all?locale=${this.$i18n.locale}&search=${searchInput}&page=1`
        );

        //zet alle searchresults uit api 1 in results
        this.results = searchResults.data.contents;
      } catch (e) {
        console.log(e);
        this.results = [];
      }
    },
  },
  created() {
    this.doSearch();
  },
  mounted() {
    // doe altijd een search op pageload
    this.doSearch();
  },
  watch: {
    //indien de for veranderd in url doe dan nog een keer de search
    "$i18n.locale": function (newVal, oldVal) {
      this.doSearch();
    },
    "$route.query.for"() {
      this.doSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  display: block !important;
  h2 {
    &:first-letter {
      text-transform: capitalize;
    }
  }
  p {
    font-size: 1rem;
    line-height: 24px;
    color: #a4aaae;
    padding-top: 12px;
    margin-bottom: 0;
  }
}
.no-results {
  max-width: 588px;
  padding: 100px 0;
  text-align: center;
  margin: 0 auto;
  @include tablet-down {
    padding: 40px 0;
  }
  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    text-align: center;
    color: #a4aaae;
    margin-top: 16px;
  }
}
</style>
